import React from 'react';
import Typography from '@/components/Typography';

type WindowWithDataLayer = Window & {
  Cookiebot: {
    show: () => void;
  };
  CookieConsentDialog: {
    toggleDetails: () => void;
  };
};

declare const window: WindowWithDataLayer;

const CookieButton = () => {
  return (
    <button
      type="button"
      className="cursor-pointer uppercase text-light-blue transition-colors duration-200 ease-in-out hover:underline"
      onClick={() => {
        if (typeof window?.Cookiebot !== 'undefined') {
          window.Cookiebot.show();
          setTimeout(() => window.CookieConsentDialog.toggleDetails(), 600);
        }
      }}
    >
      <Typography as="span" variant="body-text-1">
        Zmiana ustawień dotyczących cookies
      </Typography>
    </button>
  );
};

export default CookieButton;
