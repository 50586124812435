import React from 'react';
import Container from '@/features/Hero/Container';
import Typography from '@/components/Typography';
import { Controller, useForm } from 'react-hook-form';
import Input from '@/features/Hero/Input';
import Button from '@/components/Button/Button';
import { useStateMachine } from 'little-state-machine';
import { zodResolver } from '@hookform/resolvers/zod';
import updateAction from '@/features/Hero/actions';
import Loading from '@/assets/svg/loading.inline.svg';
import toast from 'react-hot-toast';
import { Form, formSchema } from '@/features/Hero/schema';
import { checkUrl } from '@/utils/helpers/checkUrl';
import { PrivacyPage } from '@/utils/data/seo';
import { getReferrerParams } from '@helpfind/gatsby-cookie';
import { serialize } from 'object-to-formdata';
import { ExtendedWindow } from '@/utils/types';
import InfoBox from './InfoBox';

const Step1 = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit, control, watch, formState } = useForm<Form>({
    mode: 'all',
    resolver: zodResolver(formSchema),
  });
  const { actions, state } = useStateMachine({ updateAction });

  React.useEffect(() => {
    (window as ExtendedWindow).dataLayer =
      (window as ExtendedWindow).dataLayer || [];
  }, []);

  const onSubmit = async (data: Form) => {
    const formData = new FormData();

    // Form Values
    const formValues = { ...watch() };
    Object.entries(formValues).forEach(([key, value]) => {
      if (key === 'agreements') {
        formData.append('agreements[]', value);
      } else {
        formData.append(key, value);
      }
    });

    // Files
    [...state.form.file].forEach((file: File, i: number) => {
      formData.append(`files[${i}]`, file as Blob);
    });

    // Meta
    formData.append('page', window.location.pathname);
    formData.append('sent_from_url', window.location.href);

    // All
    const formDataSerialized = serialize(
      { ...getReferrerParams() },
      { indices: true, allowEmptyArrays: true },
      formData
    );

    setIsLoading(true);

    try {
      const response = await fetch(
        `${checkUrl(process.env.GATSBY_API_URL)}api/sk/store`,
        {
          method: 'POST',
          body: formDataSerialized,
          headers: {
            Accept: 'application/json',
          },
        }
      ).then(res => res.json());

      if (response.status === 0) {
        actions.updateAction({
          fileMounted: true,
          step: 2,
          form: {
            ...state.form,
            ...data,
          },
        });
        (window as ExtendedWindow)?.dataLayer?.push({
          event: 'form.ajaxSubmit',
          formSubmissionSuccessPay: 'tak',
          product: 'BLACHY',
        });
      }
    } catch (error) {
      toast.error('Wystąpił błąd podczas przesyłania formularza.');
    }
    setIsLoading(false);
  };
  return (
    <Container className="drop py-7.5 px-6.5 lg:w-[556px] lg:p-15">
      <Button
        type="button"
        variant="text"
        arrow="back"
        arrowColor="white"
        className="mr-auto mb-3 md:mb-3.5"
        onClick={() => {
          if (state.fileMounted) {
            actions.updateAction({
              step: state.step - 1,
            });
          }
        }}
      >
        Wróć
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-2 pb-3.5">
          <Typography as="h3" variant="form-header" className="text-yellow">
            Wpisz adres mailowy, na który <br className="hidden md:block" />
            chcesz otrzymać wynik analizy
          </Typography>
          <Typography as="p" variant="form-body-text" className="text-white">
            Będą w nim ważne dane, dlatego na Twój numer telefonu otrzymasz
            wiadomość SMS z&nbsp;hasłem
          </Typography>
        </div>
        <div className="flex w-full flex-col gap-y-2">
          <Controller
            control={control}
            name="password"
            defaultValue=""
            render={({ field }) => (
              <div className="space-y-1">
                <Input
                  type="password"
                  label="Hasło do kosztorysu"
                  placeholder="Wpisz hasło"
                  {...field}
                >
                  <InfoBox variant="info">
                    Jeśli Twój kosztorys jest zabezpieczony hasłem, zostaw je
                    tutaj.
                  </InfoBox>
                </Input>
              </div>
            )}
          />
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field }) => (
              <div className="space-y-1">
                <Input
                  type="text"
                  label="Adres e-mail"
                  {...field}
                  placeholder="Wpisz e-mail"
                >
                  {formState.errors.email?.message && (
                    <InfoBox variant="error">
                      {formState.errors.email?.message as string}
                    </InfoBox>
                  )}
                </Input>
              </div>
            )}
          />
          <Controller
            control={control}
            name="phone"
            defaultValue=""
            render={({ field }) => (
              <div className="space-y-1">
                <Input
                  type="text"
                  label="Numer telefonu"
                  {...field}
                  placeholder="Wpisz numer"
                >
                  {formState.errors.phone?.message && (
                    <InfoBox variant="error">
                      {formState.errors.phone?.message as string}
                    </InfoBox>
                  )}
                </Input>
              </div>
            )}
          />

          <label
            htmlFor="agreements"
            className="mt-1.5 flex flex-row items-center gap-x-2.5"
          >
            <input
              id="agreements"
              type="checkbox"
              value="Akceptuję politykę prywatności."
              className="cursor-pointer"
              {...register('agreements')}
            />
            <Typography as="span" variant="body-text-4" className="text-white ">
              Akceptuję{' '}
              <a
                href="/polityka-prywatnosci/"
                className="hover:underline"
                title={PrivacyPage.title}
                target="_blank"
                rel="noreferrer"
              >
                politykę prywatności
              </a>{' '}
              oraz{' '}
              <a
                href="/regulamin/"
                className="hover:underline"
                title="regulamin"
                target="_blank"
                rel="noreferrer"
              >
                regulamin
              </a>{' '}
              świadczenia usług przez Helpfind sp. z o.o.
            </Typography>
          </label>
          {formState.errors.agreements?.message && (
            <InfoBox variant="error">
              {formState.errors.agreements?.message as string}
            </InfoBox>
          )}
          <Button
            type="submit"
            variant="contained"
            arrow="next"
            className="mt-1.5 w-full gap-x-1"
          >
            {isLoading ? (
              <>
                <Loading /> Ładowanie...
              </>
            ) : (
              'Wyślij'
            )}
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default Step1;
