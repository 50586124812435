import { z } from 'zod';
import { validatePhoneDebounced } from '@/utils/helpers/validatePhone';

const prev = {
  phone: '',
  isGood: false,
};

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
const ACCEPTED_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];

export const fileSchema = z.object({
  file:
    typeof window === 'undefined' // this is required if your app rendered in server side, otherwise just remove the ternary condition
      ? z.any()
      : z
          .instanceof(File)
          .refine(file => file !== null, {
            message: 'Dodaj plik, aby przejść dalej.',
          })
          .refine(
            file => {
              const fileType = file?.type || '';
              return ACCEPTED_TYPES.includes(fileType);
            },
            {
              message: 'Prześlij plik w formacie PDF, PNG lub JPG.',
            }
          )
          .refine(
            file => {
              const fileSize = file?.size || 0;
              return fileSize <= MAX_FILE_SIZE;
            },
            { message: 'Maksymalny rozmiar pliku wynosi 50 MB.' }
          ),
});

export const formSchema = z.object({
  password: z.string(),
  email: z.string().email({ message: 'Wpisz poprawny adres e-mail.' }),
  phone: z.string().refine(
    async phone => {
      if (prev.phone !== phone) {
        prev.phone = phone;
        const isValidPhone = await new Promise(resolve =>
          // eslint-disable-next-line no-promise-executor-return
          validatePhoneDebounced(phone, resolve)
        );
        prev.isGood = isValidPhone as boolean;
        return isValidPhone;
      }
      return prev.isGood;
    },
    { message: 'Wprowadź poprawny numer telefonu.' }
  ),
  agreements: z
    .boolean()
    .refine(agreement => agreement, {
      message: 'Zaznacz zgodę aby przesłać kosztorys',
    })
    .or(
      z
        .string()
        .refine(agreement => agreement === 'Akceptuję politykę prywatności.', {
          message: 'Zaznacz zgodę aby przesłać kosztorys',
        })
    ),
});

export type Form = z.infer<typeof fileSchema> & z.infer<typeof formSchema>;
