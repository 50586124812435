import React, { useEffect } from 'react';
import Seo from '@/components/Seo';
import Layout from '@/components/Layout';
import Typography from '@/components/Typography';
import Steps from '@/components/Steps';
import { PrivacyPage } from '@/utils/data/seo';
import CookieButton from '@/components/CookiebotButton/CookiebotButton';

const Page = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'open-cookie';
    script.type = 'text/javascript';
    script.innerHTML = `
      (() => {
        if (typeof window?.Cookiebot !== 'undefined') {
          window.Cookiebot.renew();
          setTimeout(() => window.CookieConsentDialog.toggleDetails(), 600);
        }
      })()
    `;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout>
      <div className="container px-4 py-5 text-dark-gray md:pb-17.5 md:pt-15 lg:max-w-[1130px]">
        <Typography as="h1" variant="header-2" className="pb-7.5">
          Polityka prywatności serwisu sprawdz-kosztorys.pl
        </Typography>
        <div className="space-y-7.5">
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § I Przetwarzanie danych osobowych
            </Typography>
            <ol className="ml-4 list-outside list-decimal space-y-3">
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ADMINISTRATOR DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Administratorem Twoich danych osobowych jest Helpfind
                  Sp.&nbsp;z&nbsp;o.o. z&nbsp;siedzibą pod adresem:
                  ul.&nbsp;Pułaskiego&nbsp;11, 63-400 Ostrów Wielkopolski,
                  NIP&nbsp;622-276-35-69, Regon&nbsp;301735830, wpisaną do
                  rejestru przedsiębiorców prowadzonym przez Sąd Rejonowy Poznań
                  - Nowe Miasto i&nbsp;Wilda w&nbsp;Poznaniu, IX&nbsp;Wydział
                  Gospodarczy KRS pod numerem 0000385405. Administrator jest
                  jednocześnie operatorem serwisu sprawdz-kosztorys.pl.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  CEL PRZETWARZANIA DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Podanie danych osobowych jest dobrowolne i&nbsp;ma na celu
                  przygotowanie oraz przedstawienie Oferty (realizacja zamówień
                  usług i&nbsp;produktów tworzonych lub współtworzonych przez
                  Administratora), a&nbsp;następnie, w&nbsp;przypadku
                  zainteresowania i&nbsp;możliwości zawarcie umowy. Helpfind
                  Sp.&nbsp;z&nbsp;o.o. przetwarza Twoje dane osobowe również
                  w&nbsp;celu prawidłowego wykonania umowy o&nbsp;świadczenie
                  usług drogą elektroniczną. Ponadto przetwarza dane
                  w&nbsp;związku z&nbsp;udzielaniem informacji o&nbsp;usługach
                  świadczonych przez Partnerów, z&nbsp;którymi Administrator
                  zawarł odrębne umowy.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  PODSTAWA PRAWNA
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Podstawą przetwarzania danych jest Rozporządzenia Parlamentu
                  Europejskiego i&nbsp;Rady&nbsp;(UE) 2016/679 z&nbsp;dnia
                  27&nbsp;kwietnia 2016&nbsp;r. w&nbsp;sprawie ochrony osób
                  fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych
                  osobowych i&nbsp;w&nbsp;sprawie swobodnego przepływu takich
                  danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                  rozporządzenie o&nbsp;ochronie danych, zwane dalej RODO),
                  w&nbsp;szczególności art.&nbsp;6 ust.&nbsp;1 oraz
                  art.&nbsp;13. Sposób kontaktu jest uzależniony od rodzaju
                  udostępnionych przez Ciebie danych kontaktowych, tj.
                  nr&nbsp;telefonu bądź adresów e-mail.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  DANE KONTAKTOWE DO ADMINISTRATORA DANYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Kontakt z&nbsp;Helpfind Sp.&nbsp;z&nbsp;o.o. jest możliwy
                  poprzez wysłanie wiadomości e-mail na adres: iod@helpfind.pl
                  lub pisemnie na adres korespondencyjny:
                  ul.&nbsp;Pułaskiego&nbsp;11, 63-400 Ostrów Wielkopolski.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ŹRÓDŁO I RODZAJ DANYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Zbierane są tylko te dane, które są niezbędne do należytego
                  świadczenia oferowanych usług. Następnie są one powierzane
                  Konsultantowi Call Center w&nbsp;celu przedstawienia
                  bezpłatnej usługi porównania ofert przedstawionych
                  w&nbsp;Serwisie.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ODBIORCY DANYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Twoje dane osobowe są w&nbsp;sposób bezpośredni przekazywane
                  do Administratora. Następnie w&nbsp;celu realizacji bezpłatnej
                  usługi, dane mogą zostać przekazane podmiotom współpracującym
                  z&nbsp;administratorem, w&nbsp;związku z&nbsp;realizacją
                  umowy, &nbsp;tym podmiotom zajmującym się profesjonalną
                  obsługą prawną - na co zgoda zostaje wyrażona m.in. poprzez
                  akceptację Regulaminu oraz Polityki prywatności Helpfind
                  Sp.&nbsp;z&nbsp;o.o. W&nbsp;momencie przekazania danych
                  klienta do partnera, z&nbsp;którym Administrator zawarł
                  odrębną umowę partner ten staje się równolegle administratorem
                  danych osobowych. Ponadto, Twoje dane osobowe mogą zostać
                  udostępnione na żądanie uprawnionych do tego podmiotów, czyli
                  m.in. prokuraturze, sądowi, policji. Dostęp do Twoich danych
                  mogą mieć także podmioty, które realizują takie usługi jak:
                  usługi programistyczne, analityczne, prawne (kancelarie
                  prawne), rzeczoznawcze, księgowe, podatkowe, kadrowo-płacowe
                  itp., dostawcy usług poczty elektronicznej, narzędzi służących
                  do kontaktu z&nbsp;nami, dostawcy przestrzeni dyskowej
                  w&nbsp;chmurze, dostawcy usług związanych z&nbsp;akcjami
                  mailingowymi oraz telekomunikacyjnymi. Lista partnerów
                  współpracujących z&nbsp;Administratorem{' '}
                  <a
                    href="https://www.dropbox.com/scl/fi/d4se608tw0z8q6hzfirwx/Lista-partner-w-SKD.pdf?rlkey=tgofsx26aizwgz2p8hr9s0hwi&e=1&st=kybktk8o&dl=0"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-light-blue transition-all duration-200 hover:underline"
                  >
                    dostępna w&nbsp;linku.
                  </a>
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Administrator Twoje dane osobowe przetwarza przez okres, który
                  jest konieczny do świadczenia Usługi lub do momentu
                  rozwiązania Umowy, rezygnacji z&nbsp;Usługi lub zgłoszenia
                  przez daną osobę sprzeciwu. Po tym okresie Twoje dane osobowe
                  będą przechowywane dla celów oraz przez okres, który jest
                  wymagany przez prawo albo dla zabezpieczenia roszczeń. Twoje
                  dane, które będą potrzebne do celów bezpośredniego marketingu
                  będą przez Administratora przetwarzane do momentu wycofania
                  przez Ciebie zgody.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  PRAWA, KTÓRE PRZYSŁUGUJĄ UŻYTKOWNIKOWI W ZWIĄZKU Z
                  PRZETWARZANIEM JEGO DANYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Przysługuje Ci prawo dostępu do pełnych Twoich danych. Możesz
                  żądać ich sprostowania, przenoszenia, ograniczenia lub
                  usunięcia oraz masz prawo do wniesienia sprzeciwu dot.
                  przetwarzania Twoich danych. Masz też prawo w&nbsp;dowolnym
                  momencie wycofać zgodę na przetwarzanie Twoich danych
                  osobowych. W&nbsp;przypadku wyrażenia zgody na otrzymywanie
                  informacji handlowej drogą elektroniczną, masz prawo ją
                  wycofać wysyłając np. e-maila na adres biuro@helpfind.pl lub
                  pod numerem telefonu 616&nbsp;350&nbsp;050. Masz również prawo
                  do przenoszenia Twoich danych osobowych. Od Administratora
                  możesz otrzymać Twoje dane osobowe, które są
                  w&nbsp;powszechnie stosowanym formacie umożliwiającym odczyt
                  maszynowy. Dane te masz prawo przesłać innemu Administratorowi
                  danych, z&nbsp;wyjątkiem danych stanowiących tajemnicę
                  przedsiębiorstwa.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  PRAWO DO WNIESIENIA SKARGI DO ORGANU NADZORCZEGO
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Masz prawo do wniesienia skargi do właściwego organu
                  nadzorczego, który zajmuje się ochroną danych osobowych. Dla
                  Polski jest to POUDO, czyli Prezes Urzędu Ochrony Danych
                  Osobowych.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  INFORMACJA O OBOWIĄZKU LUB DOBROWOLNOŚCI PODANIA DANYCH
                  OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Podanie danych osobowych jest dobrowolne. Jest to jednak
                  niezbędne do należytego świadczenia oferowanych usług.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  <CookieButton />
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § II Informacje pozostałe
            </Typography>
            <ol className="ml-4 list-outside list-decimal space-y-3">
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  RODZAJE KOMUNIKACJI I PRZETWARZANIE DANYCH W CELACH
                  KONTAKTOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W&nbsp;zależności od tego, jakiego rodzaju dane kontaktowe
                  zostały przez Ciebie podane oraz wyrażonych zgód
                  w&nbsp;miejscach podania danych, mamy prawo do kontaktu
                  z&nbsp;Tobą, m.in. w&nbsp;sprawie danej usługi, odpowiedzi na
                  pytanie, przesyłaniem informacji handlowych, biuletynów
                  informacyjnych, związku z&nbsp;marketingiem naszych usług
                  i&nbsp;produktów, przeprowadzaniem badań, pozyskiwaniem
                  opinii. Podstawą do przetwarzania danych osobowych jest Twoja
                  dobrowolna zgoda. Masz prawo w&nbsp;dowolnym czasie ją wycofać
                  - tradycyjną drogą pisemną (na adres korespondencyjny:
                  Helpfind Sp.&nbsp;z&nbsp;o.o., ul.&nbsp;Pułaskiego&nbsp;11,
                  63-400 Ostrów Wielkopolski), ustnie (za pośrednictwem
                  infolinii 616&nbsp;350&nbsp;050) lub w&nbsp;drogą
                  elektroniczną (biuro@helpfind.pl). Dane, które służą do
                  komunikacji z&nbsp;Tobą są przez nas przetwarzane do chwili,
                  gdy wycofasz swoją zgodę na otrzymywanie wiadomości. W
                  określonych sytuacjach, które są przewidziane przez przepisy
                  prawa, możemy mieć nakaz na przetwarzanie danych przez
                  określony czas.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ZMIANA POLITYKI PRYWATNOŚCI
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Jakiekolwiek zmiany dot. niniejszej polityki prywatności są
                  bez zbędnej zwłoki publikowane w&nbsp;Serwisie na podstronie
                  “Polityka Prywatności”.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ZAMÓWIENIE BEZPŁATNEJ ROZMOWY TELEFONICZNEJ
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W&nbsp;Serwisie znajdują się miejsca, które pozwalają na
                  zamówienie bezpłatnej rozmowy telefonicznej
                  z&nbsp;Konsultantem Call Center oraz zostawienia wiadomości.
                  W&nbsp;ten sposób są gromadzone numery telefonów oraz adresy
                  e-mail. Celem ich przetwarzania będzie nawiązanie kontaktu
                  z&nbsp;Tobą, a&nbsp;podstawą tego będzie wyrażona przez Ciebie
                  na to zgoda. Tą zgodę możesz w&nbsp;dowolnym momencie wycofać.
                  Mogą się zdarzyć sytuacje, w&nbsp;których zebrane
                  w&nbsp;trakcie rozmowy dane zostaną przekazane innym podmiotom
                  np. Partnerom. Każda rozmowa, która odbywa się za pomocą
                  naszej infolinii lub podmiotów, które realizują dla nas usługę
                  - telefoniczna obsługa klienta, jest nagrywana. Nagrywanie
                  rozmów odbywa się z&nbsp;uwagi na nasz uzasadniony interes,
                  czyli ochronę przed ewentualnymi roszczeniami czy oszustwami
                  oraz w&nbsp;trosce o&nbsp;jakość świadczonych przez nas usług.
                  Nagrania przechowuje się do momentu, kiedy nie będzie już
                  możliwości kierowania wobec nas bądź przez nas roszczeń dot.
                  świadczonych w&nbsp;ramach rozmowy usług. W&nbsp;określonych
                  przypadkach regulowanych przez przepisy prawa przechowywanie
                  danych może być dłuższe.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  <CookieButton />
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § III Polityka Cookies
            </Typography>
            <ol className="ml-4 list-outside list-decimal space-y-3">
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  WYJAŚNIENIE OGÓLNE
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Serwis sprawdz-kosztorys.pl w&nbsp;celu prawidłowego działania
                  Serwisu oraz ułatwienia nawigacji Użytkownikom wykorzystuje
                  pliki cookies, inaczej ciasteczka. Pliki cookies są to
                  tekstowe pliki, które zapisuje system teleinformatyczny
                  Usługodawcy w&nbsp;systemie teleinformatycznym Użytkownika.
                  Najważniejszym celem tego zapisu jest identyfikacja
                  Użytkownika w&nbsp;Serwisie, a&nbsp;także gromadzenie
                  informacji ogólnych o&nbsp;metodzie korzystania
                  z&nbsp;Serwisu. Pliki te nie posiadają informacji, które są
                  związane z&nbsp;danymi osobowymi. W&nbsp;dowolnym momencie
                  Użytkownik ma możliwość zaprzestania zapisywania plików
                  cookies. W&nbsp;tym celu należy wykorzystać ustawienia
                  internetowej przeglądarki w&nbsp;komputerze bądź
                  w&nbsp;mobilnym urządzeniu. Wyłączenie “ciasteczek” nie
                  oznacza, że niemożliwe jest korzystanie z&nbsp;Serwisu.
                  Niemniej jednak mogą pojawiać się utrudnienia
                  w&nbsp;korzystaniu z&nbsp;Serwisu bądź w&nbsp;korzystaniu
                  z&nbsp;usług, które są w&nbsp;jego ramach świadczone.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  CEL WYKORZYSTANIA PLIKÓW COOKIES
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Serwis sprawdz-kosztorys.pl wykorzystuje cookies
                  w&nbsp;następujących celach:
                  <ul className="ml-8 list-outside list-disc">
                    <li>
                      tworzenie analizy i&nbsp;statystyk, których zadaniem jest
                      pomóc zrozumieć to, w&nbsp;jaki sposób Serwis jest
                      wykorzystywany. Dzięki temu możliwe jest stałe
                      udoskonalanie zawartości oraz struktur stron, tak aby
                      odpowiadały potrzebom Użytkowników;
                    </li>
                    <li>optymalizacji stron internetowych;</li>
                    <li>
                      mierzenia czy prowadzone akcje działań marketingowych są
                      skuteczne, a&nbsp;treści i&nbsp;formy reklam dopasowane
                      oraz wyświetlane na stronach Usługodawcy;
                    </li>
                    <li>
                      zapamiętywania, czy użytkownik zgodził się bądź nie na np.
                      pokazywanie danej treści.
                    </li>
                  </ul>
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  RODZAJE PLIKÓW COOKIES
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Przez serwis sprawdz-kosztorys.pl wykorzystywane są dwa
                  rodzaje plików cookies: sesyjne oraz stałe. Pliki cookies
                  sesyjne zostają na urządzeniu danego Użytkownika do momentu
                  opuszczenia przez niego internetowej strony lub wyłączenia
                  internetowej przeglądarki. Pliki cookies stałe pozostają na
                  urządzeniu użytkownika przez ustalony czas bądź do momentu ich
                  ręcznego usunięcia. Za pomocą plików cookies przez Usługodawcę
                  są gromadzone tylko te dane, które mają charakter statystyczny
                  i&nbsp;mają na celu poprawę komfortu Użytkownikowi korzystania
                  z&nbsp;serwisu oraz zredukowania uciążliwości wyświetlania
                  materiałów reklamowych. Nie zbierane są żadne dane, które
                  mogłyby identyfikować Użytkowników jako daną osobę bądź
                  klienta.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ODNOŚNIKI DO INNYCH STRON
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W&nbsp;Serwisie mogą pojawiać się odnośniki, które będą
                  kierować do innych stron internetowych. Nie ponosimy
                  odpowiedzialności za zasady dot. prywatności, jakie obowiązują
                  na innych stronach.\nNa naszych stronach podmioty trzecie
                  zamieszczają informacje w&nbsp;formie plików cookies (tzw.
                  ciasteczek) i&nbsp;innych podobnych technologii na Twoim
                  urządzeniu końcowym (np. komputerze, smartfonie) oraz uzyskują
                  do nich dostęp. To nasi zaufani partnerzy z&nbsp;którymi stale
                  współpracujemy, aby dostosować do Twoich potrzeb
                  i&nbsp;zainteresowań reklamy na naszych i&nbsp;ich stronach,
                  a&nbsp;także usługi, które dostarczamy my i&nbsp;nasi zaufani
                  partnerzy. Takim zaufanym partnerem są podmioty z&nbsp;grupy
                  kapitałowej Wirtualnej Polski. Szczegółowe informacje na temat
                  przetwarzania Twoich danych przez Wirtualną Polskę znajdują
                  się w&nbsp;
                  <a
                    href="https://holding.wp.pl/poufnosc"
                    target="_blank"
                    rel="noreferrer"
                    className="text-light-blue transition-all duration-200 hover:underline"
                  >
                    polityce prywatności Wirtualnej Polski.
                  </a>
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ZMIANA DOT. USTAWIEŃ W WYSZUKIWARKACH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Każdy Użytkownik Serwisu sprawdz-kosztorys.pl ma możliwość
                  osobiście zmienić ustawienia dot. prywatności w&nbsp;danej
                  wyszukiwarce. Ustawienia te można zmieniać w&nbsp;taki sposób,
                  aby obsługa plików cookies w&nbsp;ustawieniach internetowej
                  przeglądarki była automatycznie blokowana albo każdorazowo
                  była informacja o&nbsp;ich umieszczeniu w&nbsp;urządzeniu
                  użytkownika. Informacje szczegółowe o&nbsp;sposobach obsługi
                  “ciasteczek” są dostępne w&nbsp;ustawieniach danej
                  przeglądarki. Poniżej znajdują się linki do informacji do
                  najbardziej popularnych przeglądarek internetowych oraz
                  urządzeń:
                  <ul className="ml-8 list-outside list-disc">
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Chrome
                      </a>
                    </li>{' '}
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.apple.com/kb/PH5042"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Safari
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.mozilla.org/pl/kb/Włączanie i wyłączanie obsługi ciasteczek"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Firefox
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://help.opera.com/Windows/12.10/pl/cookies.html"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Opera
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Android
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Safari (iOS)
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings"
                        className="text-light-blue transition-all duration-200 hover:underline"
                      >
                        Windows Phone
                      </a>
                    </li>
                  </ul>
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  BEZPIECZEŃSTWO
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Aby zapewnić wystarczające bezpieczeństwo danych podczas
                  przesyłania formularzy, w&nbsp;określonych przypadkach
                  korzystamy z&nbsp;usługi reCAPTCHA dostarczanej przez
                  Google&nbsp;Inc. Służy to przede wszystkim rozróżnieniu, czy
                  wpis został dokonany przez osobę fizyczną, czy nastąpiło
                  nadużycie przez przetwarzanie maszynowe
                  i&nbsp;zautomatyzowane. Usługa obejmuje przesłanie
                  adresu&nbsp;IP oraz ew. innych danych wymaganych przez Google
                  Inc. do działania usługi reCaptcha. Korzystanie z&nbsp;system
                  reCAPTCHA podlega{' '}
                  <a
                    href="https://policies.google.com/privacy?hl=pl"
                    target="_blank"
                    rel="noreferrer"
                    className="text-light-blue transition-all duration-200 hover:underline"
                  >
                    Polityce prywatności
                  </a>{' '}
                  oraz{' '}
                  <a
                    href="https://policies.google.com/terms?hl=pl"
                    target="_blank"
                    rel="noreferrer"
                    className="text-light-blue transition-all duration-200 hover:underline"
                  >
                    Warunkom korzystania z&nbsp;usług
                  </a>{' '}
                  firmy Google. Podstawą prawną przetwarzania danych stanowi
                  art.&nbsp;6 ust.&nbsp;1 lit.&nbsp;f) RODO (uzasadniony interes
                  prawny – interes firmy w&nbsp;zakresie zapewnienia
                  bezpieczeństwa systemu, aby chronić formularze Klientów
                  i&nbsp;swoją stronę internetową przed atakami/spamem).
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  <CookieButton />
                </Typography>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Steps />
    </Layout>
  );
};

export const Head = () => {
  return (
    <Seo title={PrivacyPage.title} description={PrivacyPage.description} />
  );
};

export default Page;
